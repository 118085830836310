import cookieHandler from './cookie-handler.js';
import Utils from '../utils/td.utils.js';

export default function handleBodyEvents() {
    ['shown.bs.collapse', 'hidden.bs.collapse'].forEach((eventType) => {
        document.body.addEventListener(eventType, (evt) => {
            window.dispatchEvent(new CustomEvent('heightChanged'));
        });
    });

    /**
     * @param {Event & {target: HTMLElement}} e
     */
    const collapseHandler = (e) => {
        const target = e.target.parentNode,
            adjustScreen = (scrolled) => {
                const topOffset = target.offsetTop - scrolled;
                if (topOffset < 0) {
                    if (!target.matches('.decisiontree')) {
                        Utils.scrollToElement(target);
                    }
                }
            };
        adjustScreen(document.documentElement.scrollTop || document.body.scrollTop);
    };

    document.body.addEventListener('shown.bs.collapse', collapseHandler);

    /**
     * @param {Event & {target: HTMLElement}} e
     */
    const clickCheckboxHandler = (e) => {
        if (e.target.matches('.CheckBox.Box')) {
            const chk = e.target.previousSibling;
            setTimeout(function () {
                chk.blur();
            }, 1);
        }
    };
    document.body.addEventListener('click.chk', clickCheckboxHandler);
}
