export default function handleToggleSelect() {
    const body = document.body;

    /**
     * @param {Event & {target: HTMLElement}} e
     */
    const clickHandler = (e) => {
        if (e.target.matches('[data-toggle="select"]')) {
            const el = e.target,
                idOfTargetOption = el.getAttribute('href').substring(1),
                /** @type {HTMLOptionElement} */
                targetOption = document.querySelector('option#' + idOfTargetOption),
                targetSelect = targetOption?.closest('select');

            e.preventDefault();

            if (targetOption.selected !== true) {
                targetOption.selected = true;
                targetSelect.dispatchEvent(new CustomEvent('change'));
            }
        }
    };
    body.addEventListener('click', clickHandler);

    /**
     * @param {Event & {target: HTMLSelectElement}} e
     */
    const changeHandler = (e) => {
        if (e.target.matches('select')) {
            const select = e.target,
                /** @type {HTMLOptionElement[]} */
                options = Array.from(select.querySelectorAll('option')),
                selectedOptions = options.filter((el) => el.selected === true);

            options.forEach((el) => {
                const idOfOption = el.getAttribute('id');
                for (const elem of document.querySelectorAll('[data-toggle="select"][href="#' + idOfOption + '"]')) {
                    elem.classList.remove('is-active');
                }
            });

            selectedOptions.forEach((el) => {
                const idOfOption = el.getAttribute('id');
                for (const elem of document.querySelectorAll('[data-toggle="select"][href="#' + idOfOption + '"]')) {
                    elem.classList.add('is-active');
                }
            });
        }
    };

    body.addEventListener('change', changeHandler);
}
