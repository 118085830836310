import Danish from './date-field.da.js';

function getDatepicker() {
    return import(/* webpackChunkName: "datepicker2" */ 'flatpickr');
}

function getDatepickerRangePlugin() {
    return import(/* webpackChunkName: "datepicker2range" */ 'flatpickr/dist/plugins/rangePlugin.js');
}
export default async function bootstrapDatepicker(element, customConfiguration) {
    const defaultConfiguration = {
        locale: Danish,
        dateFormat: 'd-m-Y',
    };

    let configuration = Object.assign({}, defaultConfiguration, customConfiguration);

    const datepicker = await getDatepicker();
    console.log(datepicker);
    if (typeof configuration.separateRageField !== 'undefined') {
        const rangePlugin = await getDatepickerRangePlugin();
        console.log(rangePlugin);
        configuration.plugins = [new rangePlugin.default({ input: configuration.separateRageField })];
        datepicker.default(element, configuration);
    } else {
        datepicker.default(element, configuration);
    }
}
