import Utils from '../utils/td.utils.js';

export default function handleAnchorLinks() {
    let hash,
        /** @type {HTMLAnchorElement} */
        hashTarget;

    if (location.hash && location.hash.indexOf('/') === -1) {
        hash = location.hash;
        hashTarget = document.querySelector('a[data-target="' + hash + '"]');

        if (!hashTarget) {
            hashTarget = document.querySelector('a[href="' + hash + '"]');
        }

        if (!hashTarget) return;

        hashTarget.dispatchEvent(new CustomEvent('click'));

        if (hashTarget.dataset.toggle) {
            setTimeout(function () {
                Utils.scrollToElement(hashTarget, true);
            }, 10);
        }

        const select = document.querySelector('option[value="' + hash + '"]')?.closest('select');

        if (select) {
            select.value = hash;
            select.dispatchEvent(new CustomEvent('change'));
        }
    }
}
