import cookieHandler from './cookie-handler.js';

export default function handleAttentionBanner() {
    var dismissed = cookieHandler.readCookie('attention_dismissed'),
        dismissedArray = dismissed ? dismissed.split(',') : [];

    Array.from(document.getElementsByClassName('AttentionBanner')).forEach((banner) => {
        if (!dismissedArray.includes(banner.dataset.attentionName)) {
            banner.classList.remove('AttentionBanner--hidden');
        }
    });

    Array.from(document.getElementsByClassName('AttentionBanner-close')).forEach((closeButton) => {
        closeButton.addEventListener('click', (event) => {
            event.preventDefault();

            const container = closeButton.closest('.AttentionBanner'),
                cookieExistingValue = cookieHandler.readCookie('attention_dismissed'),
                cookieExpireDate = new Date();

            let cookie,
                cookieValue = encodeURIComponent(container.dataset.attentionName);

            container.classList.add('AttentionBanner--slideup');

            if (cookieExistingValue !== null) {
                cookieValue = cookieExistingValue + ',' + cookieValue;
            }

            cookieExpireDate.setDate(cookieExpireDate.getDate() + 31); // 31 days.

            cookie = 'attention_dismissed=' + cookieValue + '; path=/; expires=' + cookieExpireDate.toUTCString();
            document.cookie = cookie;
        });
    });
}
