import DecisionTree from './plugins/decision-tree.js';

import handleAnchorLinks from './plugins/anchor-link.js';
import handleFlosNavBar from './plugins/flos-local-nav.js';
import handleAttentionBanner from './plugins/attention-banner.js';
import handleBodyEvents from './plugins/body-events.js';
import handleNavList from './plugins/nav-list.js';
import bootstrapDatepicker from './plugins/date-field/date-field.js';
import cookieHandler from './plugins/cookie-handler.js';

// application specific
import handleToggleSelect from './plugins/toggle-select.js';

function initCommonFunctionality(customOptions) {
    const options = Object.assign({}, customOptions);

    if (options.disableInit) {
        return;
    }

    let decisionTree = new DecisionTree();
    decisionTree.init();

    handleFlosNavBar();
    handleAnchorLinks();
    handleAttentionBanner();
    handleBodyEvents();
    handleNavList();
    handleToggleSelect();
}

export default function (customOptions) {
    initCommonFunctionality(customOptions);

    if (typeof jQuery === 'function') {
        window.$ = window.jQuery = jQuery;
    }

    // Public API
    window.TD = {
        init: initCommonFunctionality,
        Plugins: {
            CookieHandler: cookieHandler, // used in top-mpp, pension-pages, alive-mpp-savings-app and flos scripts
            LocalNav: {
                init: (selector) => {
                    handleFlosNavBar(selector); // used on product pages in BrXm
                },
            },
            datepicker: bootstrapDatepicker, // used in ragnarok-contact-info-app, alive-mpp-change-investment-risiko-app...
            NavList: {
                init: handleNavList, // used on /forsikringer page in BrXm
            },
        },
    };
}
